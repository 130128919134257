import {
  Category,
  Complement,
  FilterOption,
  FiltersType,
  Product,
  ProductType,
  SelectedFilter,
  convertToSlug,
  cortesImages,
  promotionExpired,
  promotionStarted,
} from '@onbeefapp/constants';
import { create } from 'zustand';
import {
  getProductBySlug,
  searchProducts,
} from '../queries/categories-products';
import {
  getClub,
  isCategoryAvailable,
  isProductAvailable,
} from '../utils/ProductUtils';
import { useMerchantStore } from './merchant';
import { RelatedProductsList } from '../queries/related/types';

type Products = {
  store_logo: string;
  highlights: Product[];
  categories: Category[];
  categoriesAndProducts: Record<string, Product[]>;
  search: string;
  setSelectedCategory: (id: string) => void | undefined;
  selectedCategory: string | undefined;
  handleRelatedListsProducts: (lists: RelatedProductsList[]) => {
    [list_id: string]: Product[];
  };
  // set categories and products
  setCategoriesAndProducts: (categories: Category[]) => void;
  // returns prepared products
  setCategoryProducts: (categoryID: string, products: Product[]) => Product[];
  // set highlights
  setHighlights: (products: Product[]) => void;
  // refresh product
  refreshProduct: (id: string) => void;
  // filter products by search
  filterProductsBySearch: (search: string) => Promise<void>;
  // filter products by tags and cut
  filterProducts: () => void;
  // set store logo
  setStoreLogo: (store_logo: string) => void;
  // search product by slug `${slug_name}/${slug_brand}`
  searchProductBySlug: (slug: string) => Promise<Product | undefined>;
  // filters
  filtersLoading: boolean;
  filters: SelectedFilter[];
  clearFilters: () => void;
  setSelectedFilter: (
    filter: FilterOption,
    type: FiltersType,
    remove?: boolean,
  ) => void;
};

const verifyIsOpenModal = (product: Product, productType: string) => {
  if (
    productType === ProductType.KG &&
    product.cuts &&
    product.cuts.length > 0
  ) {
    return true;
  }

  if (product.type === ProductType.KIT && product.kit_type != null) {
    return true;
  }

  if (
    product.complements.some(
      (complement) => complement.required === 1 && complement.status === 1,
    )
  ) {
    return true;
  }

  return false;
};

export const prepareProduct = (product: Product) => {
  const merchant = useMerchantStore.getState().merchant;

  const finalValue = product.price_per_kilo
    ? (product.price_per_kilo * (product.add_grams ?? 1)) / 1000
    : product.price_per_unit;

  // função para checar se os complementos vão ser interativos
  // se for interativo, o cliente pode clicar na imagem e adicionar a quantidade
  // @lista de complementos
  // @lista de imagens dos cortes
  const cortesImagesKeys = Object.keys(cortesImages);
  const isPromotion =
    product.promotion !== null &&
    promotionStarted(product.promotion) &&
    !promotionExpired(product.promotion);

  //Get a original value of product, if have price_per_kilo, use it, else use price_per_unit
  const originalValue =
    product.price_per_kilo && product.estimated_weight
      ? product.price_per_kilo
      : product.price_per_unit;

  //Get a value of product, if have promotion, use it, else use original value
  const value = isPromotion ? product?.promotion?.fixed_amount : originalValue;

  const displayPrice =
    product.price_per_kilo && product.estimated_weight
      ? merchant?.config.show_value_per_kilo
        ? value
        : (value ?? 0) * (product.estimated_weight / 1000)
      : value ?? 0;

  const club = getClub(product);

  const percentDiscount =
    originalValue !== 0
      ? (100 - (value! * 100) / originalValue!).toFixed(0)
      : '';

  const slug = product.slug;

  const productType = product.type
    ? product.type
    : product.price_per_kilo
      ? ProductType.KG
      : ProductType.UNIT;

  return {
    ...product,
    final_value: finalValue,
    price_per_kilo:
      (product?.promotion?.fixed_amount && product.price_per_kilo) ??
      product.price_per_kilo,
    price_per_unit:
      (product?.promotion?.fixed_amount && product.price_per_unit) ??
      product.price_per_unit,
    original_value: originalValue,
    discount_percent: percentDiscount,
    product_type: productType,
    displayPrice: displayPrice,
    clubPrice: club?.price,
    clubName: club?.name,
    clubID: club?.subs_id,
    complementsCut:
      typeof product?.complementsCut === 'string'
        ? JSON.parse(product?.complementsCut)
        : product?.complementsCut,
    hasCuts:
      product.complements.filter((complement) => {
        return (
          complement.status === 1 &&
          complement.name === 'Cortes' &&
          complement.visible === 0
        );
      }).length > 0 || product.cuts?.length > 0,
    complementsRequired: product.complements.filter((complement) => {
      return complement.required === 1 && complement.status === 1;
    }),
    complements: product.complements
      .filter((complement) => {
        return complement.status === 1;
      })
      .map((complement) => {
        return {
          ...complement,
          items: complement.items.filter((item) => {
            return item.status === 1;
          }),
        };
      }),
    tem_obrigatorios: product.complements.some(
      (complement) => complement.required === 1,
    ),
    // slug do produto
    slug: slug,
    // regras se precisa abrir o modal ou não
    openModal: verifyIsOpenModal(product, productType),
    // função para checar se todos os itens do complemento
    // possuem imagens
    complementsImages: (complement: Complement) => {
      const complementNamesList = complement.items.map((item) => {
        return item.name;
      });
      return complementNamesList?.flat().every((complementName) => {
        return cortesImagesKeys.includes(complementName);
      });
    },
    media:
      product.media.length > 0
        ? [...product.media]
        : [
            {
              file_url: merchant?.logo || '',
              id: '',
              order: 999,
            },
          ],
  };
};

export const prepareProducts = (products: Product[], is_highlight = false) => {
  const productsPrep = products
    .filter((product) => isProductAvailable(product))
    .map((product) => prepareProduct(product))
    .filter((product) => product.status === 1)
    .sort((a, b) => {
      if (!is_highlight) {
        return a.order - b.order;
      } else {
        return (a.order_highlight ?? 0) - (b.order_highlight ?? 0);
      }
    });
  return productsPrep;
};

const generateProductsPayload = (categories: Category[]) => {
  const availableCategories = categories.filter((category) =>
    isCategoryAvailable(category),
  );

  const categoriesAndProducts = availableCategories.reduce(
    (acc, category) => {
      if (!acc[category.id]) {
        acc[category.id] = [];
      }

      acc[category.id] = category.products.map((product) =>
        prepareProduct(product),
      );

      return acc;
    },
    {} as Record<string, Product[]>,
  );

  return {
    categoriesAndProducts: categoriesAndProducts,
    categories: availableCategories
      .map((category) => {
        return {
          ...category,
          slug: convertToSlug(category.name ?? '/not-found'),
        };
      })
      .sort((a, b) => {
        return (a?.order || 0) - (b?.order || 0);
      }) as Category[],
  };
};

export const useProductsStore = create<Products>((set, get) => ({
  store_logo: '',
  categories: [],
  highlights: [],
  productsRaw: [],
  filters: [],
  search: '',
  filtersLoading: false,
  categoriesAndProducts: {},
  selectedCategory: '',
  setSelectedCategory: (selectedCategory) => {
    set({
      selectedCategory,
    });
  },
  searchProductBySlug: async (slug) => {
    const highlight = get().highlights.find((product) => product.slug === slug);
    if (highlight) return highlight;
    const categoriesProducts = Object.values(get().categoriesAndProducts);
    for (let products of categoriesProducts) {
      for (let product of products) {
        if (product.slug === slug) return product;
      }
    }
    const product = await getProductBySlug(slug);
    return product ? prepareProduct(product) : undefined;
  },
  setStoreLogo: (store_logo) => {
    set({
      store_logo,
    });
  },
  refreshProduct(id: string) {
    set((state) => {
      const categoriesAndProducts = state.categoriesAndProducts;
      for (let category in categoriesAndProducts) {
        const products = categoriesAndProducts[category]?.map((product) => {
          if (product.id === id) return prepareProduct(product);
          return product;
        });
        if (products) categoriesAndProducts[category] = products;
      }
      return {
        categoriesAndProducts,
      };
    });
  },
  async filterProductsBySearch(search) {
    set(() => {
      return {
        search,
      };
    });
    const merchantCategories = useMerchantStore.getState().categories;
    if (!search && merchantCategories) {
      get().setCategoriesAndProducts(merchantCategories);
    } else {
      get().filterProducts();
    }
  },
  async filterProducts() {
    set({ filtersLoading: true });
    const filters = get().filters;
    const search = get().search;
    const categories = await searchProducts({
      cuts: filters.filter((f) => f.type === FiltersType.CUTS).map((f) => f.id),
      tags: filters.filter((f) => f.type === FiltersType.TAGS).map((f) => f.id),
      search,
    });
    set(() => {
      if (!categories || categories.length === 0) {
        return {
          search,
          categories: [],
          categoriesAndProducts: {},
          filtersLoading: false,
        };
      }
      return { ...generateProductsPayload(categories), filtersLoading: false };
    });
  },
  handleRelatedListsProducts(lists) {
    const preparedProducts: { [list_id: string]: Product[] } = {};
    lists.forEach(
      (list) => (preparedProducts[list.id] = prepareProducts(list.products)),
    );
    const categoriesAndProducts = get().categoriesAndProducts;
    Object.values(preparedProducts).forEach((products) => {
      products.forEach((product) => {
        const catID = product.categories?.[0]?.id;
        if (!catID) return;
        const catProducts = categoriesAndProducts[catID];
        if (catProducts?.find((p) => p.id === product.id)) return;
        categoriesAndProducts[catID] = [...(catProducts || []), product];
      });
    });
    set({ categoriesAndProducts });
    return preparedProducts;
  },
  setHighlights: (products: Product[]) =>
    set({ highlights: prepareProducts(products, true) }),
  setCategoriesAndProducts: (categories: Category[]) => {
    set(() => {
      return {
        ...generateProductsPayload(categories),
      };
    });
  },
  setCategoryProducts(categoryID, products) {
    const prepProducts = prepareProducts(products);
    set(() => {
      const categoriesAndProducts = get().categoriesAndProducts;
      categoriesAndProducts[categoryID] = prepProducts;
      return {
        categoriesAndProducts,
      };
    });
    return prepProducts;
  },
  clearFilters() {
    set({ filters: [] });
    const merchantCategories = useMerchantStore.getState().categories;
    if (merchantCategories) get().setCategoriesAndProducts(merchantCategories);
    else get().filterProducts();
  },
  setSelectedFilter(filter, type: FiltersType, remove = false) {
    set((state) => {
      let filters = state.filters;

      if (remove) {
        filters = filters.filter((f) => f.id !== filter.id);
      } else {
        filters.push({
          id: filter.id,
          name: filter.name,
          type: type,
        });
      }

      return {
        filters,
      };
    });
  },
}));
