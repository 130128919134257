import { useMyOrdersStore } from '../../../stores/my-orders';
import {
  calculateOrderKitItemTotalAndQuantity,
  currencyFormat,
  formatPackageQuantityComments,
  ItemOrderDetails,
} from '@onbeefapp/constants';
import React from 'react';
import {
  calculateOrderItemTotalAndQuantityV1,
  calculateOrderComplementTotalAndQuantityV1,
} from '@onbeefapp/constants';
import { WppComplement } from '@onbeefapp/constants/src/utils/Whatsapp/Interfaces';

const OrderDetailItems: React.FC = () => {
  const selectedOrder = useMyOrdersStore((state) => state.selectedOrder);

  if (!selectedOrder) {
    return <></>;
  }

  return (
    <div className="grid grid-cols-12">
      {selectedOrder.items?.map((item) => {
        const isProductReward = item.loyalty_programme_reward_id != null;
        const { quantity, total, item_type } =
          calculateOrderItemTotalAndQuantityV1(item);

        if (!item.coins) {
          return (
            <React.Fragment key={item.id}>
              <div className="col-span-2">
                <p className="font-bold">
                  <span>
                    {isProductReward ? (
                      <span className="text-green-600">Brinde</span>
                    ) : (
                      <>
                        {quantity}
                        {item_type}
                      </>
                    )}
                  </span>
                </p>
              </div>
              <div className="col-span-7">
                <p className="font-bold">
                  <span>{item.product?.name}</span>
                </p>
              </div>
              <div className="col-span-3 text-end">
                <p>
                  <span>{currencyFormat(isProductReward ? 0 : total)}</span>
                </p>
              </div>
              {item.comments || item.package_quantity ? (
                <div className="col-span-12">
                  <p className="text-gray-600">
                    <span>
                      {formatPackageQuantityComments(
                        item.comments,
                        item.package_quantity,
                      )}
                    </span>
                  </p>
                </div>
              ) : null}
              {item.items?.categories &&
                item.items.categories.map((cat) => {
                  return (
                    <React.Fragment key={cat.id}>
                      <div className="col-span-11">
                        <span className="font-medium">{cat.name}</span>
                      </div>
                      {cat.products.map((kitItem) => {
                        const kitItemDetail =
                          calculateOrderKitItemTotalAndQuantity(kitItem);
                        return (
                          <React.Fragment key={kitItem.id}>
                            <div className="col-span-11">
                              <p className="text-gray-600 text-body2 flex flex-col">
                                <span>{`${kitItemDetail.quantity} ${
                                  kitItem.product.name
                                }
                            ${
                              kitItemDetail.total
                                ? ` - ${currencyFormat(kitItemDetail.total)} `
                                : ''
                            }`}</span>
                              </p>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              {[...(item.cuts || []), ...(item.complements || [])].map(
                (complement) => {
                  const { price, quantity, item_type } =
                    calculateOrderComplementTotalAndQuantityV1(
                      item,
                      complement as unknown as WppComplement,
                    );

                  const comments = formatPackageQuantityComments(
                    (complement.pivot as any).comments,
                    (complement.pivot as any).package_quantity,
                  );

                  return (
                    <React.Fragment key={complement.id}>
                      <div className="col-span-11">
                        <p className="text-gray-600 text-body2 flex flex-col">
                          <span>{`${quantity}${item_type} ${
                            complement.name
                          }${` - ${currencyFormat(price)} `}`}</span>
                          {comments && (
                            <span className="text-sm ml-2">
                              OBS:{' '}
                              <span className="text-gray-500">{comments}</span>
                            </span>
                          )}
                        </p>
                      </div>
                    </React.Fragment>
                  );
                },
              )}
            </React.Fragment>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default OrderDetailItems;
