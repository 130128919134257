import {
  AddIcon,
  CheckIcon,
  currencyFormat,
  KitProduct,
  KitType,
  MinusIcon,
  Product,
  ProductType,
} from '@onbeefapp/constants';
import React from 'react';
import NoPhoto from '../../assets/images/no-photos.png';
import { useKitsStore } from '../../stores/kits';
import { useMerchantStore } from '../../stores/merchant';
import { calculateKitProductPrice } from '../../utils/KitsUtils';

interface KitItemsProps {
  product: Product;
  setRef: (element: HTMLDivElement | null, id: string) => void;
  focusCategory?: (id: string) => void;
  isExtension?: boolean;
}

export const KitItems: React.FC<KitItemsProps> = ({
  product,
  setRef,
  focusCategory,
  isExtension = false,
}) => {
  const checkCategoryAddDisabled = useKitsStore(
    (state) => state.checkCategoryAddDisabled,
  );

  const [cols, setCols] = React.useState(4);

  const updateCols = () => {
    let windowWidth = window.innerWidth - 650;
    if (isExtension) {
      windowWidth *= 0.4;
    }
    const calculatedCols = Math.floor(windowWidth / 84) - 1;
    setCols(calculatedCols > 3 ? calculatedCols : 3);
  };

  React.useEffect(() => {
    updateCols();
    window.addEventListener('resize', updateCols);
    return () => {
      window.removeEventListener('resize', updateCols);
    };
  }, []);

  const isKit = product.type === ProductType.KIT;
  const isCustom = product.kit_type != null;

  return (
    isKit && (
      <div className="w-full flex flex-col gap-2 py-2">
        {!isCustom && (
          <div className="flex flex-col gap-1 px-4">
            <span className="font-semibold text-lg">Itens do Kit</span>
          </div>
        )}
        <div className="flex flex-col gap-4">
          {isCustom ? (
            product.items?.categories?.map((category) => {
              const { disabled, count, total } = checkCategoryAddDisabled(
                product,
                category.kit_category_id,
              );
              return (
                <div
                  key={category.kit_category_id}
                  className="flex flex-col gap-2"
                >
                  <div
                    ref={(el) => setRef(el, category.kit_category_id)}
                    className="border-b border-gray-300 p-2 flex flex-row gap-2 items-center justify-between"
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <span className="font-bold text-lg">{category.name}</span>
                    </div>
                    {!isExtension && (
                      <div className="flex flex-row gap-2 items-center">
                        {category.max != null &&
                          (disabled ? (
                            <>
                              <CheckIcon className="w-6 h-6 text-green-400" />
                            </>
                          ) : (
                            <>
                              <span className="text-xs font-semibold text-white bg-gray-600 rounded-lg px-2 py-1">
                                {count} / {total}
                              </span>
                            </>
                          ))}
                        {!disabled && category.min != null && (
                          <span className="text-xs font-semibold text-white bg-gray-600 rounded-lg px-2 py-1 uppercase">
                            Obrigatório
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
                    }}
                    className="transition-all lg:ml-2 grid duration-300 transform gap-2 gap-y-4 p-2 justify-items-center"
                  >
                    {category.products.map((p) => {
                      return (
                        <KitItemCard
                          item={p}
                          product={product}
                          categoryID={category.kit_category_id}
                          focusCategory={focusCategory}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
              }}
              className="transition-all lg:ml-2 grid duration-300 transform gap-2 gap-y-4 p-2 justify-items-center"
            >
              {product.items?.categories
                ?.reduce(
                  (acc, cur) => [...acc, ...cur.products],
                  [] as KitProduct[],
                )
                .map((item) => {
                  return <KitItemCard product={product} item={item} />;
                })}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export interface KitItemCardProps {
  item: KitProduct;
  categoryID?: string;
  product: Product;
  focusCategory?: (id: string) => void;
}

export const KitItemCard: React.FC<KitItemCardProps> = ({
  item,
  categoryID,
  product,
  focusCategory,
}) => {
  const merchant = useMerchantStore((state) => state.merchant);
  const { addItem, removeItem, checkCategoryAddDisabled, getItemQuantity } =
    useKitsStore();

  const handleAdd = () => {
    if (!categoryID) return;
    addItem(product, categoryID, item);
    const { disabled: full } = checkCategoryAddDisabled(product, categoryID);
    if (full) {
      const catIndex = product.items.categories?.findIndex(
        (cat) => cat.kit_category_id === categoryID,
      );
      if (catIndex !== -1 && product.items.categories.length > catIndex) {
        const nextCat = product.items.categories[catIndex + 1];
        focusCategory && nextCat && focusCategory(nextCat?.kit_category_id);
      }
    }
  };

  const handleRemove = () => {
    if (!categoryID) return;
    removeItem(product.id, categoryID, item);
  };

  const { quantity, formatted: formattedQuantity } = getItemQuantity(
    product.id,
    categoryID || '',
    item,
  );

  const isCustom = product.kit_type != null;
  const isOpen = product.kit_type === KitType.OPEN;

  return (
    <div className="flex flex-col gap-4 items-center justify-between">
      <div className="flex flex-col gap-1">
        <div className="w-full relative flex items-center justify-center">
          {item.price ? (
            <div className="absolute top-[-6%] text-sm rounded-lg px-2 bg-primary text-contrastText font-semibold">
              + {currencyFormat(item.price)}
            </div>
          ) : null}
          <img
            src={item.product.media[0]?.file_url || merchant?.logo || NoPhoto}
            className={`object-cover w-full rounded-md border-none`}
            loading="lazy"
          />
        </div>
        {isOpen && (
          <span className="font-medium text-sm">
            {currencyFormat(calculateKitProductPrice(item))}
          </span>
        )}
        <span className="text-sm">
          {item.product.name}
          {item.cut && ' - ' + item.cut.label}
          {' ' + item.quantity}
          {item.product.type === ProductType.KG ||
          item.product.price_per_kilo != null
            ? 'g'
            : ''}
        </span>
      </div>
      {isCustom && (
        <div
          className={`flex flex-row px-2 font-semibold text-sm w-full rounded-full p-1 place-content-center bg-primary text-contrastText`}
        >
          {quantity > 0 && (
            <button
              type="button"
              className="rounded-full bg-primary"
              onClick={handleRemove}
            >
              <MinusIcon />
            </button>
          )}

          {quantity > 0 && (
            <div className="self-center w-full font-bold cursor-pointer leading-normal text-center">
              {formattedQuantity}
            </div>
          )}

          <button
            type="button"
            id={`add-product-${product.id}`}
            className={`rounded-full bg-primary ${
              quantity <= 0 && 'w-full h-full'
            }`}
            onClick={handleAdd}
          >
            <center>{quantity > 0 ? <AddIcon /> : 'Adicionar'}</center>
          </button>
        </div>
      )}
    </div>
  );
};
