import React, { useEffect, useRef, useState } from 'react'
import { useFormContext, FieldError } from 'react-hook-form'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import cx from 'classnames'
import { getValueByKey } from './utils'

export type Option = {
  value: string | number | undefined
  label: React.ReactNode | string
  img?: string
}

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder: string
  customLabel?: string | JSX.Element
  options: Option[]
  optionLabelElement?: (label: string) => JSX.Element
  name: string
}

export const Select: React.FC<SelectProps> = ({
  options,
  placeholder,
  className,
  name,
  customLabel,
  disabled,
  defaultValue,
  optionLabelElement,
}) => {
  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)
  const selectRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const selectOption = (option: Option) => {
    setSelectedOption(option)
    setValue(name, option.value)
    setIsOpen(false)
  }

  const watchedValue = watch(name)

  useEffect(() => {
    const option = options?.find((option) => option.value === watchedValue)
    setSelectedOption(option || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValue])

  useEffect(() => {
    if (!defaultValue) return
    const v = defaultValue
      ? options.find((opt) => opt.value === defaultValue) || null
      : null
    setSelectedOption(v)
    setValue(name, v?.value)
  }, [defaultValue])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const error = getValueByKey(errors, name) as FieldError | undefined

  const getScrollContainer = (
    element: HTMLElement | null
  ): HTMLElement | null => {
    if (!element) return null

    let parent = element.parentElement

    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY
      if (overflowY === 'scroll' || overflowY === 'auto') {
        return parent
      }
      parent = parent.parentElement
    }

    return null
  }

  const dropdownPosition = () => {
    if (dropdownRef.current && buttonRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const buttonRect = buttonRef.current.getBoundingClientRect()

      const scrollContainer = getScrollContainer(selectRef.current)
      const containerRect = scrollContainer?.getBoundingClientRect()

      const spaceBelow =
        (containerRect?.bottom || window.innerHeight) - buttonRect.bottom
      const spaceAbove = buttonRect.top - (containerRect?.top || 0)

      if (
        spaceBelow < dropdownRect.height &&
        spaceAbove > dropdownRect.height
      ) {
        dropdownRef.current.style.top = `-${dropdownRect.height}px`
      } else {
        dropdownRef.current.style.top = `${buttonRect.height}px`
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      dropdownPosition()
    }
  }, [isOpen])

  return (
    <div ref={selectRef} className="relative inline-block w-full text-left">
      {customLabel && (
        <label
          htmlFor={name}
          className={'z-10 -mb-2 text-left text-sm font-medium text-gray-500'}
        >
          {customLabel}
        </label>
      )}
      <input
        {...register?.(name)}
        className="w-0 h-0"
        readOnly
        onFocus={() => {
          buttonRef.current?.focus()
        }}
      />
      <button
        type="button"
        disabled={disabled}
        onMouseDown={toggleDropdown}
        className={cx(
          className,
          `inline-flex w-full px-4 py-2 border text-left ${
            error != null
              ? 'border-red-300 text-red-400'
              : 'border-gray-300 text-gray-700'
          } disabled:bg-gray-100 disabled:border-gray-400 rounded-md bg-white text-sm font-medium hover:bg-gray-50 focus:outline-none focus:ring focus:ring-gray-200`
        )}
        ref={buttonRef}
      >
        <div className="flex flex-row w-full justify-between">
          {selectedOption ? (
            <div className="flex items-center">
              {selectedOption.img && (
                <div className="w-5 mr-2">
                  <img
                    src={selectedOption.img}
                    alt={String(selectedOption.label)}
                    className="h-4"
                  />
                </div>
              )}
              {optionLabelElement && typeof selectedOption.label === 'string'
                ? optionLabelElement(selectedOption.label)
                : selectedOption.label}
            </div>
          ) : (
            placeholder
          )}

          <ChevronDownIcon
            className={cx(
              `h-5 transition-all ml-3 place-content-end self-center ${
                error != null ? 'text-red-400' : 'text-gray-700'
              }`,
              isOpen && 'rotate-180'
            )}
            strokeWidth={2}
            id="arrow-icon-select"
          />
        </div>
      </button>
      <div
        ref={dropdownRef}
        className={cx(
          'absolute transition-all max-h-64 overflow-y-scroll ease-in-out z-10 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5',
          !isOpen ? 'hidden' : 'block'
        )}
      >
        {options?.map((option, index) => (
          <div
            key={index}
            onClick={() => selectOption(option)}
            className="block px-4 py-1 text-gray-700 hover:bg-gray-100 hover:text-gray-900 items-center cursor-pointer"
          >
            {option.img && (
              <div className="w-5 mr-2 inline-block">
                <img
                  src={option.img}
                  alt={String(option.label)}
                  className="h-4"
                />
              </div>
            )}
            {option.label}
          </div>
        ))}
      </div>
    </div>
  )
}
