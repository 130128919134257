import { debounce, Product, Toast } from '@onbeefapp/constants';
import { AxiosError } from 'axios';
import React from 'react';
import CardProduct from '../../components/Cards/CardProduct';
import { useGetRelatedProductsList } from '../../queries/related';
import { GetRelatedProductsListParams } from '../../queries/related/types';
import { useMerchantStore } from '../../stores/merchant';
import { useProductsStore } from '../../stores/products';

interface RelatedProductsProps {
  product?: Product;
}

export const RelatedProducts: React.FC<RelatedProductsProps> = ({
  product,
}) => {
  const merchant = useMerchantStore((state) => state.merchant);
  const handleRelatedListsProducts = useProductsStore(
    (state) => state.handleRelatedListsProducts,
  );

  const { mutateAsync: getRelatedProductsList, data } =
    useGetRelatedProductsList();

  const [products, setProducts] = React.useState<{
    [list_id: string]: Product[];
  }>({});

  const handleGetRelatedProducts = debounce(
    async (params: GetRelatedProductsListParams) => {
      try {
        const res = await getRelatedProductsList(params);
        const p = handleRelatedListsProducts(res);
        setProducts(p);
      } catch (error) {
        const msgs = (error as AxiosError<{ error: string[] }>)?.response?.data
          ?.error;
        msgs?.forEach((msg) => Toast.error(msg));
      }
    },
    500,
  );

  const withRelated =
    product &&
    product.related_product_groups &&
    product.related_product_groups.length > 0;

  React.useEffect(() => {
    if (
      !merchant ||
      !product ||
      !product.related_product_groups ||
      product.related_product_groups.length === 0
    )
      return;
    handleGetRelatedProducts({
      merchant_id: merchant.id,
      related_product_groups: product.related_product_groups,
    });
  }, [merchant, product]);

  return (
    withRelated &&
    data &&
    data.map((list) => {
      return (
        <div className="flex flex-col gap-4 px-4 pb-16" key={list.id}>
          <span className="font-semibold">{list.name}</span>
          <div className="space-x-3 flex snap-x snap-mandatory w-full mx:auto overflow-x-scroll overflow-y-hidden">
            {products[list.id]?.map((p, i) => (
              <CardProduct product={p} key={`${list.id}-${p.id}-${i}`} />
            ))}
          </div>
        </div>
      );
    })
  );
};
