import { CloseIcon } from '@onbeefapp/constants/src/icons'
import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  position?: 'center' | 'right'
  children: React.ReactNode
  className?: string
  closeIconClassName?: string
  closeIcon?: boolean
  notClose?: boolean
  id?: string
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  position = 'center',
  children,
  className,
  closeIconClassName,
  closeIcon = true,
  notClose = false,
  id,
}) => {
  const transitionDuration = 300
  const modalContentRef = useRef<HTMLDivElement>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const handleClose = () => {
    if (!notClose) {
      setTimeout(() => setModalOpen(false), transitionDuration)
    }
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      setModalOpen(true)
    } else {
      setTimeout(() => setModalOpen(false), transitionDuration)
    }
  }, [isOpen])

  const getPositionClasses = () => {
    switch (position) {
      case 'center':
        return `top-1/2 left-1/2 transform md:h-auto ${
          isOpen
            ? '-translate-x-1/2 -translate-y-1/2'
            : 'translate-x-1/2 translate-y-1/2'
        } rounded-lg`
      case 'right':
        return `top-1/2 right-0 transform -translate-y-1/2 h-screen ${
          modalOpen ? 'translate-x-0' : 'translate-x-full'
        }`
    }
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50 ${
        isOpen ? 'block' : 'hidden'
      }`}
      id={id}
      onClick={handleClose}
    >
      <div
        ref={modalContentRef}
        onClick={(e) => void e.stopPropagation()}
        className={cx(
          `bg-white ${getPositionClasses()} absolute overflow-auto transition-transform duration-${transitionDuration}`,
          className
            ? className
            : `w-full ${
                position === 'center' ? 'max-h-[90vh]' : 'py-8'
              } sm:w-5/6 md:w-4/5 max-w-4xl`
        )}
      >
        {closeIcon && (
          <button
            type="button"
            onClick={() => handleClose()}
            className={cx('absolute top-4 right-0 mr-8', closeIconClassName)}
          >
            <CloseIcon />
          </button>
        )}
        {children}
      </div>
    </div>
  )
}
