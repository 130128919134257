import { Modal } from '@onbeef/components/modal';
import React from 'react';
import { RelatedProductsList } from '../../queries/related/types';
import { Product } from '@onbeefapp/constants';
import { useProductsStore } from '../../stores/products';
import CardProduct from '../Cards/CardProduct';

interface CartRelatedProductsModalProps {
  open: boolean;
  list?: RelatedProductsList;
  handleContinue: () => void;
  handleClose: () => void;
}

export const CartRelatedProductsModal: React.FC<
  CartRelatedProductsModalProps
> = ({ handleContinue, handleClose, open, list }) => {
  const handleRelatedListsProducts = useProductsStore(
    (state) => state.handleRelatedListsProducts,
  );

  const [products, setProducts] = React.useState<Product[]>([]);

  React.useEffect(() => {
    if (!list) return;
    const p = handleRelatedListsProducts([list]);
    p && setProducts(p[list.id] || []);
  }, [list]);

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      className="h-[95%] w-full lg:w-2/3"
    >
      <div className="w-full flex items-center justify-center p-4">
        <span className="text-lg font-medium">{list?.name}</span>
      </div>
      <div className="flex flex-col w-full gap-4 p-4">
        <div
          className="flex flex-row w-full flex-wrap overflow-auto"
          style={{ maxHeight: '78vh' }}
        >
          {products.map((product) => (
            <div className="flex mb-6 w-1/2 lg:w-1/4 justify-center items-center mx:auto overflow-x-scroll overflow-y-hidden">
              <CardProduct
                key={`relatedProductCard${product.id}`}
                product={product}
              />
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={handleContinue}
          className="rounded-lg px-4 py-2 bg-primary text-contrastText"
        >
          Continuar para o pagamento
        </button>
      </div>
    </Modal>
  );
};
