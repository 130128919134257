import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeProvider } from '@material-tailwind/react';
import {
  ICreditCardForm,
  creditCardValidation,
} from '@onbeef/components/creditCardForm';
import { Checkbox, TextInput } from '@onbeef/components/input';
import {
  AddressUtils,
  BillingAddress,
  DeliveryType,
  LoadingIcon,
  PaymentMethodType,
  ProductType,
  Toast,
  currencyToFloat,
  mascaraMoedaFormat,
  maskCPF,
  maskPhone,
} from '@onbeefapp/constants';
import Yup, { mergeSchema } from '@onbeefapp/constants/src/utils/Yup';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { TrackingContext } from '../../contexts/TrackingContext';
import { useSlug } from '../../hooks/useSlug';
import CartSummary from '../../pages/Cart/CartSummary';
import OrderDetailItemsImage from '../../pages/Orders/Detail/OrderDetailItemsImage';
import { usePayOrder } from '../../queries/checkout-link';
import { PayOrderPayload } from '../../queries/checkout-link/types';
import { usePhoneHasClub } from '../../queries/club';
import { useMerchantCache } from '../../queries/home';
import { useCreateOrder } from '../../queries/order';
import { OrderPayload } from '../../queries/order/types';
import { useCreateCard } from '../../queries/payment-card';
import { useCartStore } from '../../stores/cart';
import { useComplementsStore } from '../../stores/complements';
import { useKitsStore } from '../../stores/kits';
import { useAuthStore } from '../../stores/login';
import { useMerchantStore } from '../../stores/merchant';
import { useOrderDetails } from '../../stores/order-details';
import { useOrderStore } from '../../stores/orders';
import { usePayloadStore } from '../../stores/payload';
import { useGeneratedOrderStore } from '../../stores/pix';
import { useUserStore } from '../../stores/user';
import { getFinalQuantity } from '../../utils/CartUtils';
import { formatKitsPayload } from '../../utils/KitsUtils';
import CollapsibleItem from '../Collapse/CollapsibleItem';
import LoadingScreen from '../LoadingScreen';
import ConfirmOrderWithoutClubModal from '../Modals/ConfirmOrderWithoutClub';
import Skeleton from '../Skeleton/Skeleton';
import { FormCustomerCheckoutInfo } from './FormCustomerCheckoutInfo';

export type Checkout = {
  name: string;
  phone: string;
  document?: string;
  email: string;
  money_back: string;
  without_money_back: boolean;
  result: boolean;
  id: string;
  freight: number;
  type: string;
  duration: number;
  pickup_duration: number;
  expired_at: string;
} & ICreditCardForm;

interface FormCheckoutProps {
  setExtensionCustomCouponCode: (code: string | undefined) => void;
  setExtensionCustomDeliveryFee: (fee: number | string | undefined) => void;
  onClickContinue?: () => void;
  orderId?: string;
  extensionCustomCouponCode?: string;
  extensionCustomDeliveryFee?: number | string;
}

const FormCheckout: React.FC<FormCheckoutProps> = ({
  onClickContinue,
  orderId,
  extensionCustomCouponCode,
  extensionCustomDeliveryFee,
  setExtensionCustomCouponCode,
  setExtensionCustomDeliveryFee,
}) => {
  const {
    deliveryType,
    deliveryInstructions,
    selectedPaymentMethod,
    withoutMoneyBack,
    setWithoutMoneyBack,
    setDeliveryInstructions,
  } = useOrderDetails((state) => ({
    deliveryType: state.deliveryType,
    deliveryInstructions: state.deliveryInstructions,
    selectedPaymentMethod: state.selectedPaymentMethod,
    withoutMoneyBack: state.withoutMoneyBack,
    setWithoutMoneyBack: state.setWithoutMoneyBack,
    setDeliveryInstructions: state.setDeliveryInstructions,
  }));

  const checkoutFormValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    phone: Yup.string()
      .required('Celular é obrigatório')
      .min(14, 'Celular inválido'),
    document:
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX
        ? Yup.string()
            .required('Documento é obrigatório')
            .test('document', 'CPF/CNPJ inválido', (value) =>
              validateCPForCNPJ(value),
            )
        : Yup.string()
            .nullable()
            .test('document', 'CPF/CNPJ inválido', (value) =>
              validateCPForCNPJ(value),
            ),
    email:
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD ||
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX
        ? Yup.string().required('E-mail é obrigatório')
        : Yup.string().nullable(),
    money_back:
      selectedPaymentMethod === PaymentMethodType.MONEY && !withoutMoneyBack
        ? Yup.string().required('Troco é obrigatório')
        : Yup.string().nullable(),
    without_money_back: Yup.boolean(),
  });

  function validateCPForCNPJ(value?: string | null | undefined): boolean {
    if (!value) return true;
    if (isCheckoutLink) return true; // ignore censored taxpayer_id_number
    return cpf.isValid(value) || cnpj.isValid(value);
  }

  const validationSchema = mergeSchema(
    checkoutFormValidation,
    creditCardValidation,
  );

  const slugParams = useSlug();
  const params = useParams();
  const navigate = useNavigate();

  const {
    customer_id,
    address,
    name,
    taxpayer_id_number,
    phone,
    email,
    gender,
    birthday,
    ageGroup,
    setMoneyBack,
  } = useUserStore((state) => ({
    customer_id: state.customer_id,
    address: state.address,
    name: state.name,
    taxpayer_id_number: state.taxpayer_id_number,
    phone: state.phone,
    email: state.email,
    gender: state.gender,
    ageGroup: state.ageGroup,
    birthday: state.birthday,
    setName: state.setName,
    setTaxPayerIdNumber: state.setTaxPayerIdNumber,
    setPhone: state.setPhone,
    setEmail: state.setEmail,
    setMoneyBack: state.setMoneyBack,
  }));

  const methods = useForm<Checkout>({
    resolver: yupResolver(validationSchema) as any,
    mode: 'onBlur',
    defaultValues: {
      document: taxpayer_id_number ? maskCPF(taxpayer_id_number) : undefined,
      name: name,
      phone: phone ? maskPhone(phone) : undefined,
      email: email,
    },
  });
  const errors = methods.formState.errors;

  const [activeListItems, setActiveListItems] = React.useState(false);
  const [showConfirmOrderModal, setShowConfirmOrderModal] =
    React.useState(false);
  const { mutateAsync: phoneHasClub } = usePhoneHasClub();

  const {
    mutateAsync,
    data: createOrderData,
    isLoading: createOrderLoading,
    isSuccess,
    isError,
  } = useCreateOrder();

  const {
    mutateAsync: payOrder,
    data: payOrderData,
    isLoading: payOrderLoading,
    isSuccess: paySuccess,
    isError: payError,
  } = usePayOrder();

  const isLoading = createOrderLoading || payOrderLoading;

  const { merchant, clubs } = useMerchantStore((state) => ({
    merchant: state.merchant,
    clubs: state.clubs,
  }));
  const { isLoading: isLoadingMerchant } = useMerchantCache(slugParams.slug);

  const {
    products,
    coupon,
    deliveryTime,
    isExtension,
    isCheckoutLink,
    linkOrderDetails,
    clearCart,
    setCustomDeliveryFeeError,
    crmBonusDiscount,
    setCrmBonusData,
    setCrmBonusDiscount,
  } = useCartStore((state) => ({
    products: state.products,
    coupon: state.coupon,
    deliveryTime: state.deliveryTime,
    isExtension: state.isExtension,
    isCheckoutLink: state.isCheckoutLink,
    linkOrderDetails: state.linkOrderDetails,
    clearCart: state.clearCart,
    setCustomDeliveryFeeError: state.setCustomDeliveryFeeError,
    crmBonusDiscount: state.crmBonusDiscount,
    setCrmBonusData: state.setCrmBonusData,
    setCrmBonusDiscount: state.setCrmBonusDiscount,
  }));

  const { getComplements, clearMerchantIdComplements } = useComplementsStore(
    (state) => ({
      getComplements: state.getComplements,
      clearMerchantIdComplements: state.clearMerchantIdComplements,
    }),
  );

  const { getKitItems, clearAllKits } = useKitsStore((state) => ({
    getKitItems: state.getKitItems,
    clearAllKits: state.clearAllKits,
  }));

  const { setPayload, payload } = usePayloadStore((state) => ({
    setPayload: state.setPayload,
    payload: state.payload,
  }));

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const userLoyaltyProgramme = useAuthStore(
    (state) => state.user?.loyalty_programmes?.[0],
  );

  const { subtotal, idFreight, useReward, clubDiscountAvailable } =
    React.useContext(OrderDetailsContext);
  const createCard = useCreateCard();
  const { data: dataVisitor } = useVisitorData();
  const { setGeneratedOrder } = useGeneratedOrderStore((state) => ({
    setGeneratedOrder: state.setGeneratedOrder,
  }));
  const { addPaidOrder } = useOrderStore((state) => ({
    addPaidOrder: state.addPaid,
  }));

  const { track } = React.useContext(TrackingContext);

  const handleClubAuth = () => {
    navigate('/login', { state: { goBack: '/checkout' } });
  };

  const handleClearCartClick = () => {
    setDeliveryInstructions('');
    clearCart();
    clearMerchantIdComplements();
    clearAllKits();
    setCrmBonusData(undefined);
    setCrmBonusDiscount(0);
  };

  const submitCreateCard = (data: Checkout, orderPayload: OrderPayload) => {
    let billing_address = {} as BillingAddress;
    if (data.billing_address.without && address) {
      billing_address = {
        address: address?.street_name,
        complement: address?.street_complement || '',
        zip_code: address?.zip,
        city: address?.city,
        state: address?.state,
        country: 'BR',
      };
    } else {
      billing_address = {
        address: data.billing_address.street_name,
        complement: data.billing_address.street_complement || '',
        zip_code: data.billing_address.zip,
        city: data.billing_address.city,
        state: data.billing_address.state,
        country: 'BR',
      };
    }

    const cardPayload = {
      token: dataVisitor?.visitorId,
      payment_method: 'CREDIT_CARD',
      number: data.card.number,
      holder_name: data.card.name.toUpperCase(),
      holder_document: taxpayer_id_number ? taxpayer_id_number : null,
      email: email ? email : null,
      exp_month: data.card.expiration.split('/')[0]!,
      exp_year: data.card.expiration.split('/')[1]!,
      cvv: data.card.cvv,
      billing_address: billing_address,
      phone: phone ? phone : null,
      name: name ? name : null,
      taxpayer_id_number: taxpayer_id_number ? taxpayer_id_number : null,
    };

    if (isCheckoutLink) {
      orderPayload.payment = {
        installments: 1,
        card_payload: cardPayload,
      };
      submitOrder(orderPayload);
    } else {
      createCard.mutate(cardPayload);
    }
  };

  React.useEffect(() => {
    if (createCard.data) {
      if (createCard.isSuccess && payload && createCard.data) {
        const newPayload = payload;
        newPayload.payment = {
          installments: 1,
          card: {
            id: createCard?.data?.card?.id,
            cvv: methods.getValues('card.cvv'),
          },
        };
        track('OnCustomerAtCheckoutCreateCreditCard', {});
        submitOrder(newPayload);
      }
    }
    if (createCard.error) {
      const msg = (createCard.error as any).error?.[0];
      msg && Toast.error(msg);
    }
  }, [createCard.data, createCard.error]);

  React.useEffect(() => {
    if (createOrderData) {
      if (Array.isArray(createOrderData?.error)) {
        createOrderData?.error?.map((error: any) => {
          if (
            error ==
            'Loja não tem o horário selecionado disponível para agendamento!'
          ) {
            navigate(`/schedule`);
          }
          Toast.error(error);
        });

        return;
      } else if (createOrderData.error === true) {
        return Toast.error(createOrderData?.message);
      }
      setGeneratedOrder(createOrderData.id, createOrderData, !!useReward);
    }

    if (isSuccess) {
      handleClearCartClick();
    }

    if (
      isSuccess &&
      createOrderData?.payment_codes?.status === 'AUTHORIZED_PENDING_CAPTURE' &&
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD
    ) {
      track('OnCustomerSubmitOrder', {
        payment_method: selectedPaymentMethod,
        delivery_type: deliveryType,
        subtotal: subtotal,
      });
      navigate(
        `/checkout/${createOrderData?.id}/validate-cents/${createOrderData?.code}`,
        {
          replace: true,
        },
      );
      addPaidOrder(params.id);
    }
    if (createOrderData?.payment_codes?.error) {
      Toast.error(createOrderData.payment_codes?.error?.toString());
    } else {
      if (
        isSuccess &&
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX &&
        createOrderData
      ) {
        track('OnCustomerAtCheckoutContinueWithPix', {
          payment_method: selectedPaymentMethod,
          delivery_type: deliveryType,
          subtotal: subtotal,
        });
        navigate(`/checkout/pix/${createOrderData.id}`, { replace: true });
      } else if (
        isSuccess &&
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD &&
        createOrderData
      ) {
        track('OnCustomerSubmitOrder', {
          payment_method: selectedPaymentMethod,
          delivery_type: deliveryType,
          subtotal: subtotal,
        });
        navigate(
          `/checkout/${createOrderData.id}/validate-cents/${createOrderData.code}`,
          { replace: true },
        );
      } else {
        if (createOrderData) {
          track('OnCustomerSubmitOrder', {
            payment_method: selectedPaymentMethod,
            delivery_type: deliveryType,
            subtotal: subtotal,
          });
          if (isExtension && onClickContinue) {
            onClickContinue();
          } else {
            navigate(`/status/${createOrderData.id}`, { replace: true });
          }
        }
      }
    }
  }, [isSuccess, isError]);

  React.useEffect(() => {
    if (payOrderData) {
      setGeneratedOrder(payOrderData.id, payOrderData, !!useReward);
    }

    if (
      paySuccess &&
      payOrderData?.payment_codes?.status === 'AUTHORIZED_PENDING_CAPTURE' &&
      selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD
    ) {
      navigate(
        `/checkout/${payOrderData.id}/validate-cents/${payOrderData.code}`,
        {
          replace: true,
        },
      );
      addPaidOrder(params.id);
    }

    if (payOrderData?.payment_codes?.error) {
      Toast.error(payOrderData.payment_codes?.error?.toString());
    } else {
      if (
        paySuccess &&
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_PIX &&
        payOrderData
      ) {
        clearCart();
        navigate(`/checkout/pix/${payOrderData.id}`, { replace: true });
      } else if (
        paySuccess &&
        selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD &&
        payOrderData
      ) {
        navigate(
          `/checkout/${payOrderData.id}/validate-cents/${payOrderData.code}`,
          { replace: true },
        );
      }
    }
  }, [paySuccess, payError]);

  React.useEffect(() => {
    if (isCheckoutLink && linkOrderDetails) {
      methods.setValue('name', linkOrderDetails.customer.name);
      methods.setValue('phone', linkOrderDetails.customer.phone);
      methods.setValue(
        'document',
        linkOrderDetails.customer.taxpayer_id_number,
      );
      methods.setValue('email', linkOrderDetails.customer.email);
    } else {
      methods.setValue('name', name);
      methods.setValue('phone', phone ? maskPhone(phone) : '');
      methods.setValue(
        'document',
        taxpayer_id_number ? maskCPF(taxpayer_id_number) : '',
      );
      methods.setValue('email', email);
    }
  }, [isCheckoutLink, linkOrderDetails]);

  const without_money_back = methods.watch('without_money_back');

  React.useEffect(() => {
    setWithoutMoneyBack(without_money_back);
  }, [without_money_back]);

  const submitOrder = React.useCallback(
    (payloadOrder: OrderPayload) => {
      const handleErrors = (error: any) => {
        const response = error.response.data;
        if (Array.isArray(response?.error)) {
          response?.error?.map((error: any) => {
            Toast.error(error);
          });
          return;
        } else if (response.error === true) {
          return Toast.error(response?.message);
        }
      };

      if (isCheckoutLink && linkOrderDetails) {
        const linkPayload: PayOrderPayload = {
          method_payment: payloadOrder.method_payment!,
          email: payloadOrder.email ? payloadOrder.email : null,
          taxpayer_id_number: payloadOrder.taxpayer_id_number
            ? payloadOrder.taxpayer_id_number
            : null,
          payment: { card: payloadOrder.payment?.card_payload },
        };
        payOrder({
          orderID: linkOrderDetails.id,
          code: linkOrderDetails.code,
          payload: linkPayload,
        }).catch(handleErrors);
        return;
      }

      const extension = payloadOrder.origin === 'EXTENSION';
      if (extension) {
        if (extensionCustomCouponCode && extensionCustomCouponCode !== '') {
          payloadOrder.code_coupon = extensionCustomCouponCode;
        }
        const customExtensionCustomFeeAmount: number = currencyToFloat(
          String(extensionCustomDeliveryFee),
        );
        if (extensionCustomDeliveryFee && customExtensionCustomFeeAmount >= 0) {
          payloadOrder.freight_amount = String(customExtensionCustomFeeAmount);
        }
      }
      mutateAsync({ payload: payloadOrder, extension: extension }).catch(
        handleErrors,
      );
    },
    [
      extensionCustomCouponCode,
      extensionCustomDeliveryFee,
      isCheckoutLink,
      linkOrderDetails,
    ],
  );

  const validations = (data: Checkout) => {
    if (!selectedPaymentMethod) {
      Toast.error('Selecione uma forma de pagamento');
      return true;
    }

    if (deliveryType === DeliveryType.DELIVERY && !address && isExtension) {
      Toast.error('Selecione um endereço');
      return true;
    }

    if (
      !withoutMoneyBack &&
      selectedPaymentMethod === PaymentMethodType.MONEY &&
      currencyToFloat(data.money_back) < subtotal
    ) {
      Toast.error('Valor do troco deve ser maior que o valor total do pedido');
      return true;
    }

    if (
      isExtension &&
      deliveryType !== DeliveryType.WITHDRAWAL &&
      address &&
      (!address.latitude || !address.longitude) &&
      !extensionCustomDeliveryFee
    ) {
      Toast.error(
        'O valor da taxa de entrega é obrigatório quando o endereço foi colocado manualmente',
      );
      setCustomDeliveryFeeError(true);
      return true;
    } else {
    }

    return false;
  };

  const onSubmit = async (data: Checkout) => {
    const validation = validations(data);
    if (validation === true) return;

    // suporta apenas 1 clube
    const subsClubID = Object.values(products).find(
      (p) => p.clubSubscriptionID != null,
    )?.clubSubscriptionID;

    const cartProducts = Object.values(products);

    const orderPayload: OrderPayload = {
      order_id: orderId ?? null,
      merchant_id: merchant?.id ?? '',
      origin: isExtension ? 'EXTENSION' : 'CATALOG',
      items: cartProducts.map((product) => {
        const kitItems = getKitItems(product.id);
        const complements = getComplements(product.id);
        const quantity = getFinalQuantity(product);

        return {
          product_id: product.id,
          quantity: quantity,
          discount: 0,
          comments: product.comments ?? '',
          package_quantity: product.package_quantity,
          complements: complements.map((complement) => {
            const quantity =
              complement.type === ProductType.KG && complement.items_kg === 1
                ? complement.quantity * 100
                : complement.quantity;
            return {
              id: complement.id,
              quantity,
            };
          }),
          cuts: product.selectedCuts,
          items: kitItems ? formatKitsPayload(kitItems) : undefined,
        };
      }),
      use_bonus: crmBonusDiscount !== 0,
      loyalty_programme: useReward
        ? {
            customer_loyalty_programm_id:
              userLoyaltyProgramme!.customer_loyalty_programme_id,
            reward: {
              id: userLoyaltyProgramme!.reward!.id,
            },
          }
        : undefined,
      type_delivery:
        merchant?.delivery_details === 6 ? DeliveryType.UBER : deliveryType,
      comments:
        selectedPaymentMethod === PaymentMethodType.MONEY && !withoutMoneyBack
          ? `${deliveryInstructions} Troco para: ${data.money_back}`
          : deliveryInstructions,
      method_payment: selectedPaymentMethod,
      name: name,
      phone: phone,
      taxpayer_id_number: taxpayer_id_number,
      email: email,
      gender: gender,
      age_group: ageGroup,
      birthday: birthday,
      subscription_club_id: subsClubID,
      amount: (Math.round((subtotal + Number.EPSILON) * 100) / 100).toFixed(2),
      delivery: {
        quote: {
          id: idFreight,
        },
        address: AddressUtils.getFormattedAddress(address),
        notes: null,
      },
    };

    if (isExtension) {
      orderPayload.customer_id = customer_id;
    }

    if (deliveryType === DeliveryType.DELIVERY && address) {
      orderPayload.address = {
        city: address?.city,
        country: address?.country,
        neighborhood: address?.neighborhood,
        latitude: address?.latitude || null,
        longitude: address?.longitude || null,
        state: address?.state,
        street_complement: address?.street_complement ?? '',
        street_name: address?.street_name,
        street_number: address?.street_number,
        street_reference: address?.street_reference ?? '',
        zip: address?.zip,
      };
      orderPayload.lat_destiny = address.latitude || null;
      orderPayload.long_destiny = address.longitude || null;
    } else {
      orderPayload.lat_destiny = null;
      orderPayload.long_destiny = null;
    }

    if (coupon) {
      orderPayload.code_coupon = coupon.code;
      orderPayload.type = coupon.type;
    } else {
      orderPayload.code_coupon = undefined;
      orderPayload.type = undefined;
    }

    if (deliveryType === DeliveryType.DELIVERY && deliveryTime) {
      orderPayload.schedule = deliveryTime;
    } else {
      orderPayload.schedule = undefined;
    }

    setPayload(orderPayload);

    if (
      clubs &&
      clubs.length > 0 &&
      clubDiscountAvailable &&
      !isAuthenticated
    ) {
      const { has } = await phoneHasClub({
        id: merchant!.id,
        phone: data.phone,
      });
      if (has) {
        setShowConfirmOrderModal(has);
        return;
      }
    }

    if (selectedPaymentMethod === PaymentMethodType.CHECKOUT_CREDIT_CARD) {
      submitCreateCard(data, orderPayload);
    } else {
      submitOrder(orderPayload);
    }
  };

  const theme = {
    input: {
      styles: {
        base: {
          container: {
            minWidth: 'min-w-[100px]',
          },
        },
      },
    },
    checkbox: {
      styles: {
        base: {
          label: {
            fontSize: 'text-sm',
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ThemeProvider value={theme as any}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col items-start justify-start w-full">
              {isCheckoutLink && (
                <div className="flex flex-col gap-2 px-2 w-full">
                  <CollapsibleItem
                    title="Items do pedido"
                    active={activeListItems}
                    onClick={() => setActiveListItems(!activeListItems)}
                  >
                    <div className="flex flex-col text-sm">
                      <OrderDetailItemsImage
                        items={linkOrderDetails?.items}
                        withImage
                      />
                    </div>
                  </CollapsibleItem>
                </div>
              )}

              {!isExtension && (
                <FormCustomerCheckoutInfo
                  setExtensionCustomCouponCode={setExtensionCustomCouponCode}
                  setExtensionCustomDeliveryFee={setExtensionCustomDeliveryFee}
                />
              )}

              <div className="fixed bottom-0 flex flex-col items-start justify-start w-full p-3 bg-white rounded-t-xl">
                {/* Solicitar troco para o usuário caso selecione Dinheiro */}
                {selectedPaymentMethod === PaymentMethodType.MONEY && (
                  <div className="flex flex-col gap-2 w-full mt-2 rounded-md">
                    <Skeleton
                      isLoading={isLoadingMerchant}
                      className="w-full rounded-md h-11"
                    >
                      {!without_money_back && (
                        <div>
                          <TextInput
                            name="money_back"
                            customLabel="Troco para quanto?"
                            validationSchema={{}}
                            autoComplete="money_back"
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                              mascaraMoedaFormat(e)
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              methods.setValue('money_back', e.target.value);
                              setMoneyBack(e.target.value);
                              //add trigger
                              methods.trigger('money_back');
                            }}
                            className={`py-2 px-4 pl-2 rounded-md w-full text-xs`}
                            error={errors?.money_back !== undefined}
                          />
                          <div className="text-red-400 text-xs">
                            {errors?.money_back && errors?.money_back?.message}
                          </div>
                        </div>
                      )}
                      <Checkbox
                        name="without_money_back"
                        customLabel="Sem troco"
                      />
                    </Skeleton>
                  </div>
                )}

                {/* Termos de Serviço e Política de Uso de Dados */}
                <div className="w-full mt-2 rounded-md">
                  {isExtension && (
                    <CartSummary
                      extensionCustomDeliveryFee={extensionCustomDeliveryFee}
                      extensionCustomCouponCode={extensionCustomCouponCode}
                      setExtensionCustomCouponCode={
                        setExtensionCustomCouponCode
                      }
                      setExtensionCustomDeliveryFee={
                        setExtensionCustomDeliveryFee
                      }
                    />
                  )}
                  <Skeleton
                    isLoading={isLoadingMerchant}
                    className="w-16 h-2 rounded-lg"
                  >
                    <div className="text-xs mt-2 text-[#7F8EA1] ml-1">
                      Ao enviar seu pedido, você concorda com os{' '}
                      <br className="sm:hidden" />
                      <a
                        href="https://docs.google.com/document/d/1Kv2DSSvgbt1X0KILj7kklXPS7Deg9xi94_9ggp7u_fk/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline font-regular text-[#000]"
                      >
                        Termos de Serviço e Política de Uso de Dados
                      </a>{' '}
                      da Onbeef e do açougue.
                    </div>
                  </Skeleton>
                </div>
                {/* Button confirm */}
                <div className="flex flex-row items-center justify-center w-full mt-3">
                  <Skeleton
                    isLoading={isLoadingMerchant}
                    className="w-full rounded-md h-11"
                  >
                    <button
                      className="w-full flex justify-center p-2 text-contrastText bg-primary rounded-md text-sm"
                      type="submit"
                    >
                      {isLoading ? (
                        <LoadingIcon />
                      ) : selectedPaymentMethod ===
                        PaymentMethodType.CHECKOUT_PIX ? (
                        'Gerar PIX'
                      ) : isCheckoutLink ? (
                        'Realizar pagamento'
                      ) : (
                        'Fazer pedido'
                      )}
                    </button>
                  </Skeleton>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </ThemeProvider>
      {!isExtension && <LoadingScreen active={isLoading} />}
      {
        <ConfirmOrderWithoutClubModal
          title="Desbloqueie suas Vantagens!"
          onConfirm={handleClubAuth}
          onCancel={() => payload && submitOrder(payload)}
          isOpen={showConfirmOrderModal}
          setIsOpen={setShowConfirmOrderModal}
        >
          <p>
            Olá, {methods.getValues('name')}! Você é assinante do{' '}
            {clubs?.[0]?.name}, mas está prestes a finalizar sua compra sem
            estar logado.
          </p>
        </ConfirmOrderWithoutClubModal>
      }
    </React.Fragment>
  );
};

export default FormCheckout;
